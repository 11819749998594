<template>
  <div>
    <div class="check-step bg-white d-flex shadow-sm">
      <div class="item active">
        <span>01.</span>
        <span>確認商品</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item">
        <span>02.</span>
        <span>取貨方式</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item finish">
        <span>03.</span>
        <span>金流付款</span>
        <span class="material-icons-outlined"></span>
      </div>
    </div>

    <div class="check-cart">
      <div class="menu-list" v-if="cartList !== null">
        <div class="item" v-for="cart in cartList" :key="cart.id">
          <div class="row">
            <app-image :image_url="cart.product.image_url"></app-image>
            <div class="col-8">
              <div class="title row no-gutters justify-content-between align-items-center">
                <h3>{{ cart.product.title }}</h3>
              </div>
              <div class="introduction">{{ cart.addition_products }}</div>
              <!-- <div class="price">
                {{ formatMoney(cart.price) }}
              </div>-->
              <product-price :item="{ ...cart, cartVip: cart.product.is_vip }" />
            </div>
            <div
              class="col"
              v-if="isProductSoldOut(cart.product)"
              style="color: red"
              @click="handleChange(0, cart.quantity, cart.id)"
            >
              售完
              <br />
              <el-button
                type="text"
                icon="el-icon-delete"
                @click="handleChange(0, cart.quantity, cart.id)"
              >點我，移除商品</el-button>
            </div>
          </div>
          <div class="row justify-content-end mt-2" v-if="!isProductSoldOut(cart.product)">
            <div class="col">
              <div class="input-group mb-3">
                <el-input-number
                  v-model="cart.quantity"
                  @change="
                    (oldval, newval) => handleChange(oldval, newval, cart.id)
                  "
                  label="Quantity"
                  :ref="
                    (el) => {
                      if (el) quantity[cart.id] = el;
                    }
                  "
                  :max="99"
                ></el-input-number>

                <span
                  v-if="
                    !cart.product.stock_unlimited &&
                    cart.product.stock < getProductQuantity(cart.product.id)
                  "
                  style="color: red"
                >庫存不足，至多{{ cart.product.stock }}件</span>
              </div>
            </div>
          </div>
        </div>
        <div class="cart-summary row no-gutters justify-content-end">
          <div class="col-auto p-2">
            <span>{{ $t("CART.TOTAL.GOODS") }}：</span>
            <span class="text-primary">{{ cartQuantity }}</span>
            <span>{{ $t("CART.TOTAL.GOODS_UNIT") }}</span>
          </div>
          <div class="col-auto p-2">
            <span>{{ $t("CART.TOTAL.MONEY") }}</span>
            <span class="text-primary">$ {{ formatMoney(cartTotal) }}</span>
            <span>{{ $t("CART.TOTAL.MONEY_UNIT") }}</span>
          </div>
        </div>
      </div>
    </div>

    <nav class="checkout-menu">
      <div class="row no-gutters justify-content-center w-100 fixed-bottom bg-light">
        <div class="col px-3">
          <a
            :href="'/shop/category/' + category_id"
            class="btn bg-dark btn-block shadow text-white my-2"
            id="optionClose"
          >{{ $t("CART.BUTTON.BACK") }}</a>
        </div>
        <div class="col px-3" :disabled="isUpdating">
          <a
            href="/shop/checkout"
            class="btn bg-main btn-block shadow text-white my-2"
          >{{ $t("CART.BUTTON.NEXT") }}</a>
        </div>
      </div>
    </nav>
  </div>
</template>
<style>
.my-2 {
  padding: 8% 0;
}
.menu-detail .item-option fieldset:last-child {
  margin-bottom: 6rem;
}
</style>
<script>
import { inject } from "vue";
import { useStore } from "vuex";
import { computed, getCurrentInstance, ref, onBeforeUpdate } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { formatMoney } from "@/utils/tools";
import storage from "@u/storage";
import AppImage from "@/components/AppImage.vue";
import ProductPrice from "@/components/ProductPrice.vue";
storage.config = { type: "session" };

export default {
  name: "CartsView",
  components: {
    AppImage,
    ProductPrice,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    const shop_name = inject("shop_name"); // 取得商店名稱
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const category_id = storage.getItem("shop_category_id", {
      type: "session",
    });

    //computed: getter
    const isLoading = computed(() => store.getters["carts/isLoading"]);
    const myIsLoading = computed(() => {
      emit("onLoading", isLoading.value);
      return props.isLoading;
    });
    //數量
    const cartQuantity = computed(() => store.getters["carts/cartQuantity"]);
    //總金額
    const cartTotal = computed(() => store.getters["carts/cartTotal"]);

    //一開始就要取資料
    store.dispatch("carts/getCarts", { kind: 2 }).then(() => {
      if (cartList.value.length === 0) {
        if (storage.getItem("shop_category_id")) {
          if (window.history.length > 2) {
            if (storage.getItem("shop_category_id") > 0)
              router.push(
                "/shop/category/" + storage.getItem("shop_category_id")
              );
            else router.go(-1);
          } else {
            // router.push("/");
            router.push("/member");
          }
        }
      }
    });
    const cartList = computed(() => store.getters["carts/cartList"]);
    const isUpdating = computed(() => store.getters["carts/isUpdating"]);
    const updatedData = computed(() => store.getters["carts/updatedData"]);

    //methods
    const quantity = ref([]);
    onBeforeUpdate(() => {
      quantity.value = [];
    });
    const isProductSoldOut = (product) => {
      if (!product.is_available) return true;
      if (!product.stock_unlimited && product.stock < 1) return true;
      return false;
    };
    const handleChange = (newVal, oldVal, cart_id) => {
      store
        .dispatch("carts/updateCartQuantity", {
          id: cart_id,
          quantity: newVal,
          kind: 2,
        })
        .then(() => {}) //透過watch監控
        .catch(() => {
          onAlert("error");
        });
    };
    const onAlert = (type) => {
      let message =
        type === "success"
          ? t("ALERT.CART_UPDATED", { msg: t("ALERT.SUCCESS") })
          : t("ALERT.CART_UPDATED", { msg: t("ALERT.ERROR") });
      proxy.$message({
        type: type,
        message: message,
      });
    };
    const getProductQuantity = (product_id) => {
      return cartList.value
        .reduce((acc, cart) => {
          return cart !== undefined && cart.product.id === product_id
            ? cart.quantity + acc
            : 0;
        }, 0)
        .toFixed(2);
    };

    return {
      shop_name,
      myIsLoading,
      cartList,
      cartQuantity,
      cartTotal,
      isUpdating,
      updatedData,
      quantity,
      category_id,
      handleChange,
      isProductSoldOut,
      onAlert,
      formatMoney,
      getProductQuantity,
    };
  },
  watch: {
    cartList: function (data) {
      if (this.cartList.length === 0) {
        if (storage.getItem("shop_category_id") > 0)
          this.$router.push(
            "/shop/category/" + storage.getItem("shop_category_id")
          );
        else this.$router.push("/shop/category");
      }
    },
    updatedData: function () {
      if (this.updatedData !== null && !this.isUpdating) {
        this.updatedData.is_updated === true
          ? this.onAlert("success")
          : this.onAlert("error");
      }
    },
  },
};
</script>
